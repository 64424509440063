import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

import ContentWrapper from "../../components/contentWrapper";
import Button from "../../components/button";
import Typography from "../../components/typography";
import * as s from "./styles.module.scss";

const NotFoundPage = (): JSX.Element => {
  const backToHome = () => {
    navigate("/");
  };

  return (
    <Layout background>
      <Seo
        title="Ooops! Page Not Found"
        description="Page has not been found. Click the link below to go back to the home."
      />
      <ContentWrapper fillAvailableHeight>
        <div className={s.error}>
          <Typography variant="h2" align="center">
            Not sure what you're looking for here... But theres nothing to see!
          </Typography>
          <Button
            ariaLabel="Return to the start/home page"
            border
            uppercase
            content={{
              btnText: "Let's Start from Scratch",
              onClick: backToHome,
            }}
          />
        </div>
      </ContentWrapper>
    </Layout>
  );
};

export default NotFoundPage;
